import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import styled from "@emotion/styled";
import {css} from "@emotion/core";

const WomenBizForLeadersSection6Background = styled.div`
  min-height: 728px;
  background-color: ${props => props.theme.colors.white};
  display: block;
  color: ${props => props.theme.colors.gray_dark};
  margin: 0;
`
const WomenBizForLeadersSection6Content = styled.div`
  padding-top: 100px;
  padding-bottom: 125px;
  text-align:center;
  display:block;
`
const WomenBizForLeadersSection6Title = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom: 25px;
  @media(min-width:1600px){
    width:1160px;
  }
  @media(max-width:1199px){
    font: 56px/60px Abril Fatface;
  }
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
  }
`
const WomenBizForLeadersSection6Text = styled.div`
  font: 20px/28px Roboto;
  max-width: 474px;
  margin-left: auto;
  margin-right: auto;
`

const WomenBizForLeadersSection6TestimonySection = styled.div`
  display:flex;
  text-align:center;
  margin-top:54px;
  @media(max-width:768px){
    display:block;
    margin: auto;
  }
`
const WomenBizForLeadersSection6Testimonies = [
  {
    "imgPath": "/page-womenbiz-for-leaders-section6-img1.png",
    "testimony": '“I am thrilled with the mentor choice WomenWorks gave me since my mentor was an established person in the product space. After doing a session, my manager clearly noted my growth and even asked how I was able to handle stress much better.”',
    "jobTitle": "Associate Product Manager, Traveloka"

  },
  {
    "imgPath": "/page-womenbiz-for-leaders-section6-img2.png",
    "testimony": '“I felt I was a victim at my work, since I was often passed for promotion. I talked with my mentor, and using her tips, talked candidly with my superior. Now, I’m currently given the chance to step-up and lead a team. I’m looking very much to the promotion!”',
    "jobTitle" : "Associate Product Manager, NinjaVan"

  }
]

export default function WomenBizForLeadersSection6() {
  return (
  <WomenBizForLeadersSection6Background>
    <Container>
      <WomenBizForLeadersSection6Content>
          <WomenBizForLeadersSection6Title>
            Meet our mentees who have grown in their careers through WomenWorks
          </WomenBizForLeadersSection6Title>
          <WomenBizForLeadersSection6TestimonySection>
            {WomenBizForLeadersSection6Testimonies.map(testimony=>(
              <div css={css`
                  margin-left:auto;
                  margin-right:auto;
                  @media(min-width:769px){
                    margin-right: min(10px);
                  }
                  @media(max-width:768px){
                    margin-top:25px;
                  }`}  key={testimony.imgPath}>
                <img css={
                  css`margin-bottom:26px;
                  height:92px;
                  `} alt="WomenWorks - Company Logo" src={testimony.imgPath}/>
                <WomenBizForLeadersSection6Text>
                  {testimony.testimony} <br/>
                </WomenBizForLeadersSection6Text>
                <WomenBizForLeadersSection6Text css={css`margin-top:15px;`}>
                  <b>{testimony.jobTitle}</b>
                </WomenBizForLeadersSection6Text>
              </div>
            ))}
          </WomenBizForLeadersSection6TestimonySection>
      </WomenBizForLeadersSection6Content>
    </Container>
  </WomenBizForLeadersSection6Background>
  )
}
