import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Container from "react-bootstrap/Container"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/free-solid-svg-icons"

const SectionBackground = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/28px Roboto;
  padding-bottom: 130px;
  padding-top: 130px;
  @media (max-width: 991px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`

const SectionTitle = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom: 35px;
  min-height: 93px;
  text-align: center;
  @media (max-width: 1199px) {
    font: 56px/60px Abril Fatface;
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    font: 2.5rem/3rem Abril Fatface;
    margin-bottom: 15px;
  }
`

const FAHeart = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: ${props => props.theme.colors.accent_yellow_hover};
`
const WomenBizForLeadersSection2ItemList = [
  "We're a community-based talent market-place, with a mission to increase the number of women leaders across industries.",
  "Take part in our talent pool by joining us as mentors and expand your professional network.",
  "Our professional talents are ready to be hired talents as the next Head, VP, C-Level, or even Co-founders, and we can help get you connected soon.",
  "Join us as a hiring company and get exclusive access to our A-List talent pool that are the next generation of leaders in Indonesia.",
]

export default function WomenBizForLeadersSection9(props) {
  return (
    <SectionBackground id="womenbiz-section8">
      <Container css={css`
                    justify-content:center;
                    align-text:center;
                    @media (min-width: 991px) {
                      max-width: 800px;
                    }
                  `}>
        <SectionTitle>
        Recruiting (Coming Soon)
        </SectionTitle>
            <Row>
              <Col xs="12">
                <p
                  css={css`
                    margin-bottom: 24px;
                  `}
                >
                  WomenWorks commits to empowering the Indonesian workforce to reach their full career potential - by making the hidden and hot jobs accessible to our highly curated pool of women leaders and talents.
                </p>
                {WomenBizForLeadersSection2ItemList.map(item => (
                  <Row key={item}>
                    <Col
                      xs="auto"
                      css={css`
                        padding-right: 0;
                      `}
                    >
                      <FAHeart icon={faHeart} />
                    </Col>
                    <Col
                      css={css`
                        padding-left: 7px;
                        margin-bottom: 20px;
                        font: normal 20px/28px Roboto;
                      `}
                    >
                      {item}
                    </Col>
                  </Row>
                ))}
                </Col>
            </Row>
      </Container>
    </SectionBackground>
  )
}
