import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import { Link } from "gatsby";
import Container from 'react-bootstrap/Container';

const IndexSection2Section1Background = styled.div`
  background-color: ${props => props.theme.colors.white_dark};
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/28px Roboto;
  position:relative;
  overflow: hidden;
  @media(max-width:767px){
    min-height: 0px;
  }
`

const SectionItem = styled.div`
  display: inline-block;
  @media(max-width:767px){
    margin-top:0;
    margin-bottom:50px;
  }
`

const SecondSectionTitle = styled.div`
  margin-top:160px;
  color: ${props => props.theme.colors.gray_dark};
  font: 58px/62px Abril Fatface;
  @media(max-width:991px){
    margin-top:0;;
  }
  @media(max-width:767px){
    margin-top:20px;
    font: 2.5rem/3rem Abril Fatface;
  }
  @media(max-width:320px){
    font: 2rem/2.5rem Abril Fatface;
  }
`
const SecondSectionText = styled.div`
  color: ${props => props.theme.colors.gray_dark};
  font: 19px/28px Roboto;
  margin-top:35px;
  max-width:90%;
  @media(max-width:767px){
    margin-top:20px;
  }
  .row {
    margin-bottom:20px;
  }
  span {
    margin-top:20px;
    margin-left:7px;
  }
`
const SecondSectionButton = styled.div`
  margin-bottom:110px;
  margin-top:5px;
  @media(max-width:768px) {
    margin-bottom:30px;
  }
`
const ButtonFill2 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color} !important;
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color} !important;
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color} !important;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_violet.hovered.shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_green.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  @media(min-width:1199px) {
    margin-top:0px;
  }
  @media(min-width:1200px){
    width:270px;
  }
  @media(max-width:1199px){
    width:100%;
  }
`
export default function IndexSection2Section1(props) {
  return (
  <IndexSection2Section1Background backgroundColor={props.Background} color={props.TextColor}>
      <img alt="WomenWorks - He For She" src="/page-womenbiz-for-leaders-section5-img1.png" 
        css={css`
          @media(max-width:991px) {
            display:none;
          }
          @media(min-width:992px) {
            position: absolute;
            right: 0;
            top:0;
            height: 691px;
            max-width: 45vw;
            object-fit: cover;
          }
          @media(min-width:1600px) {
            display:none;
          }
      `}></img>
      <Container>
          <SectionItem>
            <Row>
              <Col xs="12" lg="5" css={css`@media(max-width:767px){margin-top:50px;}`} >
              </Col>
              <Col xs="12" lg="7">
                <img alt="WomenWorks - He For She" src="/page-womenbiz-for-leaders-section5-img1.png" 
                  css={css`
                    @media(max-width:767px) {
                      width:100%;
                      margin-bottom:15px;
                    }
                    @media(min-width:768px) {
                      object-fit: cover;
                      height: 250px;
                      width:100%;
                      margin-bottom:30px;
                      object-position: 15% 15%;
                    }
                    @media(min-width:992px) {
                      display:none;
                    }
                    @media(min-width:1600px) {
                      display:block;
                      position:absolute;
                      object-fit:scale-down;
                      width: 832px;
                      height: 800px;
                      margin-left: 15%;
                    }
                `}></img>
              </Col>
              <Col lg="8" xl="7">
          <SecondSectionTitle>
            Our experienced Founders
          </SecondSectionTitle>
          <SecondSectionText>
            <Row>
              <Col xs="auto" css={css`@media(min-width:992px){padding-right:50px;}`}>
              Windy and Fransiska are both leading women entrepreneurs in the tech scene in Indonesia. Both passionate about growing women talents, Windy and Fransiska started WomenWorks in March 2020 when they both still held full time roles as the CPO of AwanTunai and the Head of Engineering of MamiKos, respectively.
              </Col>
            </Row>
          </SecondSectionText>
          <SecondSectionButton>
            <Row>
              <Col sm lg="5" xl="4" css={css`@media(max-width:768px){margin-bottom:20px;}`}>
                <Link to={`/who-we-are`}>
                  <ButtonFill2>Meet the founding team</ButtonFill2>
                </Link>
              </Col>
            </Row>
          </SecondSectionButton>
        </Col>
            </Row>
          </SectionItem>
      </Container>
  </IndexSection2Section1Background>
  )
}

