import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../components/layout";
import Section1 from "../sections/womenbiz-for-leaders-section1";
import Section2 from "../sections/womenbiz-for-leaders-section2";
import Section3 from "../sections/womenbiz-for-leaders-section3";
import Section4 from "../sections/womenbiz-for-leaders-section4";
import Section5 from "../sections/womenbiz-for-leaders-section5";
import Section6 from "../sections/womenbiz-for-leaders-section6";
import Section7 from "../sections/womenbiz-for-leaders-section7";
import Section8 from "../sections/womenbiz-for-leaders-section8";
import Section9 from "../sections/womenbiz-for-leaders-section9";
import SEO from "../components/seo"

export default class WomenBizForBusiness extends React.Component {
  componentDidMount() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.innerHTML = `
    var anchor1 = document.getElementById('Womenbiz1-LnD-Button');
    anchor1.onclick = function() {
            fbq('track', 'WomenBiz Services', {page: "WomenBiz Services", action: "Womenbiz1-LnD-Button"});
    };
    var anchor2 = document.getElementById('Womenbiz1-EmployerBranding-Button');
    anchor2.onclick = function() {
            fbq('track', 'WomenBiz Services', {page: "WomenBiz Services", action: "Womenbiz1-EmployerBranding-Button"});
    };
    var anchor3 = document.getElementById('Womenbiz1-Recruiting-Button');
    anchor3.onclick = function() {
            fbq('track', 'WomenBiz Services', {page: "WomenBiz Services", action: "Womenbiz1-Recruiting-Button"});
    };
    var anchor4 = document.getElementById('Womenbiz2-BookLnD-Button');
    anchor4.onclick = function() {
            fbq('track', 'WomenBiz Services', {page: "WomenBiz Services", action: "Womenbiz2-BookLnD-Button"});
    };
    var anchor5 = document.getElementById('Womenbiz2-DownloadOurPrograms-Button');
    anchor5.onclick = function() {
            fbq('track', 'WomenBiz Services', {page: "WomenBiz Services", action: "Womenbiz2-DownloadOurPrograms-Button"});
    };
    var anchor6 = document.getElementById('Womenbiz3-BookUsForYourEvents-Button');
    anchor6.onclick = function() {
            fbq('track', 'WomenBiz Services', {page: "WomenBiz Services", action: "Womenbiz3-BookUsForYourEvents-Button"});
    };
    var anchor7 = document.getElementById('Womenbiz3-MeetMentors-Button');
    anchor7.onclick = function() {
            fbq('track', 'WomenBiz Services', {page: "WomenBiz Services", action: "Womenbiz3-MeetMentors-Button"});
    };
    var anchor8 = document.getElementById('Womenbiz5-MeetMentors-Button');
    anchor8.onclick = function() {
            fbq('track', 'WomenBiz Services', {page: "WomenBiz Services", action: "Womenbiz5-MeetMentors-Button"});
    };
    var anchor9 = document.getElementById('Womenbiz6-GetConnected-Button');
    anchor9.onclick = function() {
            fbq('track', 'WomenBiz Services', {page: "WomenBiz Services", action: "Womenbiz6-GetConnected-Button"});
    };
    var anchor10 = document.getElementById('Womenbiz6-DownloadOurPrograms-Button');
    anchor10.onclick = function() {
            fbq('track', 'WomenBiz Services', {page: "WomenBiz Services", action: "Womenbiz6-DownloadOurPrograms-Button"});
    };
    `
    ;
    document.body.appendChild(s);
  }

  render(){
  return (
    <Layout
      LogoColor={props => props.theme.colors.secondary_green} 
      HeaderMenuColor={props => props.theme.colors.gray_dark} 
      firstSectionBGColor={props => props.theme.colors.white}

      //button
      HeaderButtonColor={props => props.theme.buttons.primary_small_button_yellow_gray.normal.color} 
      HeaderButtonBorder={props => props.theme.buttons.primary_small_button_yellow_gray.normal.border_color} 
      HeaderButtonBackground={props => props.theme.buttons.primary_small_button_yellow_gray.normal.background_color}
      
      HeaderButtonColorHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.color}
      HeaderButtonBorderHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.border_color} 
      HeaderButtonBackgroundHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.background_color}

      HeaderButtonColorPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.color}
      HeaderButtonBorderPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.border_color} 
      HeaderButtonBackgroundPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.background_color}
    >

      <SEO title={"WomenBiz for Business"} description={"We help you build gender-equal talent development strategy for your company"} />

      <Section1/>
      <Section2/>
      <Section8/>
      <Section9/>
      <Section3/>
      <Section4/>
      <Section5/>
      <Section6/>
      <Section7/>
    </Layout>
  )}
}
