import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { Row } from "react-bootstrap";

const WomenBizForLeadersSection7Background = styled.div`
  min-height: 468px;
  background-color: ${props => props.theme.colors.primary_violet};
  color: ${props => props.theme.colors.white};
  display: block;
  margin: 0;
`
const WomenBizForLeadersSection7Content = styled.div`
  padding-top: 100px;
  padding-bottom: 125px;
  text-align:center;
  display:block;
  @media(max-width:767px){
    padding-bottom: 50px;
    padding-top : 50px;
  }
`
const WomenBizForLeadersSection7Title = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom: 25px;
  @media(max-width:1199px){
    font: 56px/60px Abril Fatface;
  }
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
  }
`
const WomenBizForLeadersSection7Text = styled.div`
  font: 20px/28px Roboto;
  max-width: 768px;
  min-height: 84px;
  margin-left: auto;
  margin-right: auto;
`

const ButtonFill6 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_yellow_violet.normal.color} !important;
  background-color: ${props => props.theme.buttons.primary_button_yellow_violet.normal.background_color} !important;
  border-color: ${props => props.theme.buttons.primary_button_yellow_violet.normal.border_color} !important;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_yellow_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_yellow_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_yellow_violet.hovered.shadow} !important;
    @media(min-width:1199px){
      margin-top:45px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_yellow_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_yellow_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:45px;
      margin-bottom:3px;
    }
  }
  @media(min-width:1199px) {
    margin-top:48px;
  }
  @media(max-width:921px){
    width:100%;
    margin-bottom:20px;
  }
  @media(max-width:767px){
    margin-top: 50px;
    width:100%;
  }
`
const ButtonOutline1 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.normal.color};
  background-color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.normal.background_color};
  border-color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.normal.border_color};
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.secondary_big_button_yellow_violet.hovered.shadow} !important;
    @media(min-width:1199px){
      margin-top:45px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_big_button_yellow_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:45px;
      margin-bottom:3px;
    }
  }
  @media(min-width:1199px) {
    margin-top:48px;
  }
  @media(min-width:922px){
    margin-left:25px;
  }
  @media(max-width:921px){
    width:100%;
    margin-bottom:50px;
  }
  @media(max-width:767px){
    width:100%;
    margin-bottom:50px;
  }
`

export default function WomenBizForLeadersSection7() {
  return (
  <WomenBizForLeadersSection7Background>
    <Container>
      <WomenBizForLeadersSection7Content>
          <WomenBizForLeadersSection7Title>
            Still have questions?
          </WomenBizForLeadersSection7Title>
          <WomenBizForLeadersSection7Text>
            On the fence about investing your time for a mentorship? Sign up for a free 15-minute pre-mentorship consultation session to find out more!
          </WomenBizForLeadersSection7Text>
          <Row>
          <Col>
            <OutboundLink target="_blank" rel="noreferrer" href="https://bit.ly/prementorshipWW">
              <ButtonFill6>
                Connect with the WomenWorks team
              </ButtonFill6>
              </OutboundLink>
              <OutboundLink target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/13vFQgFKOJD6y2OL5vCU0iZdf0a3yCv8L/view?usp=sharing">
                <ButtonOutline1>
                  Download our programs
                </ButtonOutline1>
            </OutboundLink>
          </Col>
          </Row>
      </WomenBizForLeadersSection7Content>
    </Container>
  </WomenBizForLeadersSection7Background>
  )
}
