import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from 'gatsby';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Container from "react-bootstrap/Container";

const ThirdSectionBackground = styled.div`
  background-color : ${props => props.theme.colors.accent_yellow70};
  color : ${props => props.theme.colors.gray_dark};
  text-align:center;
  min-height:758px;
`

const ThirdSectionTitle = styled(Col)`
  color : ${props => props.theme.colors.gray_dark};
  margin-top:100px;
  font: 58px/62px Abril Fatface;
  text-align:center;
  margin-bottom:41px;
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-top:50px;
    margin-bottom:35px;
  }
`
const ThirdSectionContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const ThirdSectionCol = styled(Col)`
   font: 28px/36px Roboto;
   margin-top:50px;
   text-align:center;
   @media(max-width:767px){
    margin-top: 35px;
    margin-bottom:0;
   }
`

const SvgIcon = styled.img`
   margin-bottom: 16px;
`
const ItemTitle = styled.div`
  font: 28px/32px Abril Fatface;
  color: ${props => props.theme.colors.gray_dark};
  margin-bottom:10px;
  min-height:70px;
`
const ButtonFill6 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color} !important;
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color} !important;
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color} !important;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  margin-top:48px;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_violet.hovered.shadow} !important;
    @media(min-width:1199px){
      margin-top:45px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:45px;
      margin-bottom:3px;
    }
  }
  @media(min-width:1024px) {
    margin-top:48px;
  }
  @media(max-width:921px){
    width:100%;
    margin-bottom:20px;
  }
  @media(max-width:767px){
    margin-top:10px;
    width:100%;
  }
`
const ButtonOutline1 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.secondary_big_button_violet.normal.color} !important;
  background-color: transparent !important;
  border-color: ${props => props.theme.buttons.secondary_big_button_violet.normal.border_color} !important;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.secondary_big_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_big_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.secondary_big_button_violet.normal.shadow} !important;
    @media(min-width:1199px){
      margin-top:45px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.secondary_big_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_big_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:45px;
      margin-bottom:3px;
    }
  }
  @media(min-width:1024px) {
    margin-top:48px;
  }
  @media(min-width:922px){
    margin-left:25px;
  }
  @media(max-width:921px){
    width:100%;
  }
  @media(max-width:767px){
    width:100%;
    margin-bottom:50px;
  }
`

const ItemContent = styled.div`
  font: 20px/28px Roboto;
  color: ${props => props.theme.colors.gray_dark};
`

export default function MasterclassSection3() {
  return (      
  <ThirdSectionBackground>
      <Container>
      <ThirdSectionContainer>
            <ThirdSectionTitle>
              Why work with us
            </ThirdSectionTitle>
            <Row>
                <ThirdSectionCol xs="12" md="4">
                    <SvgIcon src="/page-masterclass-section3-icon1.svg"/> 
                    <ItemTitle>Curated star mentors</ItemTitle>
                    <ItemContent>Our mentors have an average of 6+ years of experience in unicorn start-ups and multinational corporates accross 20 industries.</ItemContent>
                </ThirdSectionCol>
                <ThirdSectionCol xs="12" md="4">
                    <SvgIcon src="/page-masterclass-section3-icon2.svg"/> 
                    <ItemTitle>Fast-growing network</ItemTitle>
                    <ItemContent>We have a total of 1500+ mentees already kick starting their growth journey with us and 3000+ sessions of mentorship booked. Our masterclasses were attended by 1000+ ambitious talents.</ItemContent>
                </ThirdSectionCol>
                <ThirdSectionCol xs="12" md="4" css={css`@media(max-width:991px){margin-bottom:50px;}`}>
                    <SvgIcon src="/page-masterclass-section3-icon3.svg"/> 
                    <ItemTitle>Strong engagement</ItemTitle>
                    <ItemContent>We have worked with 40+ brands, companies, and communities.</ItemContent>
                </ThirdSectionCol>
            </Row>
              <Col css={css`margin-bottom:139px;@media(max-width:992px){margin-bottom:30px;align-text:center;}`}>
                <Link id="Womenbiz5-MeetMentors-Button" to="/the-ladybosses">
                <ButtonFill6>
                    Meet our Mentors
                </ButtonFill6>
                </Link>
                <Link to="/who-we-are">
                <ButtonOutline1 
                buttontype="secondary_big_button_violet" 
                verticalpadding="16px"
                horizontalpadding="24px">
                    Meet the team
                </ButtonOutline1>
                </Link>
              </Col>
        </ThirdSectionContainer>
      </Container>
  </ThirdSectionBackground>
  )
} 