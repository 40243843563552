import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Container from "react-bootstrap/Container"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/free-solid-svg-icons"
import { OutboundLink } from "gatsby-plugin-google-analytics";

const WomenBizForLeadersSection2Background = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/28px Roboto;
  padding-bottom: 130px;
  padding-top: 130px;
  @media (max-width: 991px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`

const WomenBizForLeadersSection2Title = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom: 35px;
  min-height: 93px;
  text-align: center;
  @media (max-width: 1199px) {
    font: 56px/60px Abril Fatface;
  }
  @media (max-width: 921px) {
    min-height: 70px;
    margin-bottom: 5px;
  }
  @media (max-width: 767px) {
    font: 2.5rem/2.5rem Abril Fatface;
    min-height: 50px;
    margin-bottom: 5px;
  }
`

const FAHeart = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: ${props => props.theme.colors.gray_dark};
`

const ButtonFill6 = styled(Button)`
  height: 60px;
  padding: 0 28px 0 28px;
  margin-bottom: 20px;
  margin-right:20px;
  color: ${props =>
    props.theme.buttons.primary_button_violet.normal.color} !important;
  background-color: ${props =>
    props.theme.buttons.primary_button_violet.normal
      .background_color} !important;
  border-color: ${props =>
    props.theme.buttons.primary_button_violet.normal.border_color} !important;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props =>
      props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props =>
      props.theme.buttons.primary_button_violet.hovered
        .background_color} !important;
    border-color: ${props =>
      props.theme.buttons.primary_button_violet.hovered
        .border_color} !important;
    box-shadow: 0px 3px 20px
      ${props => props.theme.buttons.primary_button_violet.hovered.shadow} !important;
    @media (min-width: 1199px) {
      margin-top: 24px;
      margin-bottom: 3px;
    }
  }
  &:focus,
  &.focus,
  &:active,
  &.active {
    color: ${props =>
      props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props =>
      props.theme.buttons.primary_button_violet.pressed
        .background_color} !important;
    border-color: ${props =>
      props.theme.buttons.primary_button_violet.pressed
        .border_color} !important;
    box-shadow: none !important;
    @media (min-width: 1199px) {
      margin-top: 24px;
      margin-bottom: 3px;
    }
  }
  @media (min-width: 1199px) {
    margin-top: 27px;
  }
  @media (max-width: 921px) {
    width: 100%;
    margin-bottom: 20px;
  }
`
const ButtonOutline1 = styled(Button)`
  height: 60px;
  margin-bottom: 20px;
  color: ${props =>
    props.theme.buttons.secondary_big_button_violet.normal.color} !important;
  background-color: transparent !important;
  border-color: ${props =>
    props.theme.buttons.secondary_big_button_violet.normal
      .border_color} !important;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props =>
      props.theme.buttons.secondary_big_button_violet.hovered.color} !important;
    background-color: ${props =>
      props.theme.buttons.primary_button_violet.pressed
        .background_color} !important;
    border-color: ${props =>
      props.theme.buttons.secondary_big_button_violet.hovered
        .border_color} !important;
    box-shadow: 0px 3px 20px
      ${props => props.theme.buttons.secondary_big_button_violet.normal.shadow} !important;
    @media (min-width: 1199px) {
      margin-top: 24px;
      margin-bottom: 3px;
    }
  }
  &:focus,
  &.focus,
  &:active,
  &.active {
    color: ${props =>
      props.theme.buttons.secondary_big_button_violet.pressed.color} !important;
    background-color: ${props =>
      props.theme.buttons.primary_button_violet.pressed
        .background_color} !important;
    border-color: ${props =>
      props.theme.buttons.secondary_big_button_violet.pressed
        .border_color} !important;
    box-shadow: none !important;
    @media (min-width: 1199px) {
      margin-top: 24px;
      margin-bottom: 3px;
    }
  }
  @media (min-width: 1199px) {
    margin-top: 27px;
  }
  @media (max-width: 921px) {
    width: 100%;
  }
`

const WomenBizForLeadersSection2ItemList = [
  "1:1 consultations and small group consultations (for both men and women) with experienced professionals from various industries.",
  "Virtual interactive master classes with a group of min. 50 participants with topics targeted to help your employees reach their top potential.",
  "Executive 1:1 self-development coaching ran by certified ICTs.",
  "Systematical matching between mentors and mentees will be provided by us.",
]

export default function WomenBizForLeadersSection2(props) {
  return (
    <WomenBizForLeadersSection2Background id="womenbiz-section2">
      <Container>
        <WomenBizForLeadersSection2Title>
                Learning & Development
        </WomenBizForLeadersSection2Title>
        <Row>
          <Col
            xs="12"
            lg="4"
            css={css`
              width: 100%;
              text-align: center;
              @media (min-width: 1199px) {
                margin-left: -50px;
                margin-right: 50px;
              }
            `}
          >
            <img
              alt="WomenWorks - good leaders attract good talent"
              src="/page-womenbiz-for-leaders-section2-img1@2x.png"
              css={css`
                max-width: 400px;
                @media (max-width: 1198px) {
                  width: 100%;
                }
              `}
            ></img>
          </Col>
          <Col xs="12" lg="8">
            <Row>
              <Col>
                <p
                  css={css`
                    margin-bottom: 24px;
                    min-height: 54px;
                    max-height: 216px;
                  `}
                >
                  Companies are severely missing out by not cultivating women
                  employees to be leaders.{" "}
                  We are here to support your HR team to help the company
                  outperform through equal gender efforts.
                </p>
                {WomenBizForLeadersSection2ItemList.map(item => (
                  <Row key={item}>
                    <Col
                      xs="auto"
                      css={css`
                        padding-right: 0;
                      `}
                    >
                      <FAHeart icon={faHeart} />
                    </Col>
                    <Col
                      css={css`
                        padding-left: 7px;
                        margin-bottom: 20px;
                        font: normal 20px/28px Roboto;
                      `}
                    >
                      {item}
                    </Col>
                  </Row>
                ))}
                <Row>
                  <Col xs="12">
                  <OutboundLink id="Womenbiz2-BookLnD-Button" target="_blank" rel="noreferrer" href={"mailto:yours@womenworks.io" +
                      "?subject=WomenBiz%20enquiry" +
                      "&body=Dear%20WomenWorks%20team,%20we're%20interested%20to%20learn%20more%20about%20how%20L%26D%20programs%20could%20help%20our%20company.%20Please%20find%20below%20our%20contact%20information%20where%20you%20can%20reach%20us%20to%20discuss%20further.%0D%0A%0D%0A" +
                      "Name:%0D%0A" +
                      "Company:%0D%0A" +
                      "Mobile:%0D%0A" +
                      "Email%0D%0A%0D%0A" +
                      "Thank%20you!"}>
                      <ButtonFill6>Book us for your L&D programs</ButtonFill6>
                    </OutboundLink>
                    <OutboundLink id="Womenbiz2-DownloadOurPrograms-Button" target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/13vFQgFKOJD6y2OL5vCU0iZdf0a3yCv8L/view?usp=sharing">
                      <ButtonOutline1>Download our programs</ButtonOutline1>
                    </OutboundLink>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </WomenBizForLeadersSection2Background>
  )
}
