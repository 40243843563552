import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Container from "react-bootstrap/Container";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const SectionBackground = styled.div`  
background-color: ${props => props.theme.colors.white};
color: ${props => props.theme.colors.gray_dark};
font: 20px/28px Roboto;
min-height: 669px;
padding-top: 110px;
@media (max-width: 767px) {
  min-height: 0;
  padding-top: 50px;
  overflow: hidden;
}
`

const SectionTitle = styled.div`
font: 58px/62px Abril Fatface;
margin-bottom: 35px;
min-height: 132px;
@media (max-width: 1199px) {
  font: 56px/60px Abril Fatface;
}
@media (max-width: 767px) {
  font: 2.5rem/3rem Abril Fatface;
  margin-bottom: 17.5px;
}
`

const SectionItem = styled.div`
  display: inline-block;
  @media (max-width: 767px) {
    margin-top: 0;
    margin-bottom: 0px;
  }
`

const SectionCol = styled(Col)`
   margin-top:50px;
   text-align:center;
   justify-content:center;
   @media(max-width:767px){
    margin-top: 35px;
    margin-bottom:0;
   }
`
const ButtonOutline1 = styled(Button)`
  height: 60px;
  color: ${props =>
    props.theme.buttons.secondary_big_button_violet.normal.color} !important;
  background-color: transparent !important;
  border-color: ${props =>
    props.theme.buttons.secondary_big_button_violet.normal
      .border_color} !important;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props =>
      props.theme.buttons.secondary_big_button_violet.hovered.color} !important;
    background-color: ${props =>
      props.theme.buttons.primary_button_violet.pressed
        .background_color} !important;
    border-color: ${props =>
      props.theme.buttons.secondary_big_button_violet.hovered
        .border_color} !important;
    box-shadow: 0px 3px 20px
      ${props => props.theme.buttons.secondary_big_button_violet.normal.shadow} !important;
    @media (min-width: 1199px) {
      margin-top: 24px;
      margin-bottom: 3px;
    }
  }
  &:focus,
  &.focus,
  &:active,
  &.active {
    color: ${props =>
      props.theme.buttons.secondary_big_button_violet.pressed.color} !important;
    background-color: ${props =>
      props.theme.buttons.primary_button_violet.pressed
        .background_color} !important;
    border-color: ${props =>
      props.theme.buttons.secondary_big_button_violet.pressed
        .border_color} !important;
    box-shadow: none !important;
    @media (min-width: 1199px) {
      margin-top: 24px;
      margin-bottom: 3px;
    }
  }
  @media (min-width: 1199px) {
    margin-top: 27px;
  }
  @media (max-width: 921px) {
    width: 100%;
  }
`
const SectionImage = styled.img`
  margin-top: 10px;
  margin-bottom:12px;
  @media (max-width: 992px) {
    width: 100%;
  }
`
const ItemTitle = styled.div`
  font: 28px/28px Abril Fatface;
  color: ${props => props.theme.colors.primary_violet};
  min-height:60px;
  max-width:250px;
  text-align:center;
  @media (max-width: 767px) {
    margin-top: 17.5px;
  }
`
const ItemContent = styled.div`
  font: 20px/28px Roboto;
  color: ${props => props.theme.colors.gray_dark};
  min-height:168px;
  @media (max-width: 1199px) {
    min-height:220px;
  }
  @media (max-width: 992px) {
    min-height:280px;
  }
  @media (max-width: 767px) {
    min-height:0px;
    width: 100%;
    margin-bottom:15px;
  }
`

export default function BWomenBizForLeadersSection1() {
  return (
  <SectionBackground>
      <Container>
          <SectionItem>
                <SectionTitle>
                We offer women-first growth programs for professionals
                </SectionTitle>
                <Row>
                <SectionCol xs="12" md="4">
                    <Row css={css`justify-content:center;`}>
                      <ItemTitle>Learning & Development</ItemTitle>
                    </Row>
                    <SectionImage src="/page-womenbiz-for-leaders-section1-img2.png"/> 
                    <ItemContent>WomenWorks offer women-first growth programs tailored for professionals at your company across a complete talent’s life-cycle.</ItemContent>
                    {/* <OutboundLink target="_blank" rel="noreferrer" href="">
                      <ButtonOutline1>Learn more</ButtonOutline1>
                    </OutboundLink> */}
                    <AnchorLink id="Womenbiz1-LnD-Button" to="/womenbiz-services#womenbiz-section2" title="Our team">
                    <ButtonOutline1>Learn more</ButtonOutline1>
                    </AnchorLink>
                </SectionCol>
                <SectionCol xs="12" md="4">
                    <Row css={css`justify-content:center;`}>
                      <ItemTitle>Employer Branding</ItemTitle>
                    </Row>
                    <SectionImage src="/page-womenbiz-for-leaders-section1-img3.png"/> 
                    <ItemContent>WomenWorks supports companies in building their corporate reputation through online professional networks and social media, focusing on diversity and inclusion.</ItemContent>
                    <AnchorLink id="Womenbiz1-EmployerBranding-Button" to="/womenbiz-services#womenbiz-section9" title="Our team">
                    <ButtonOutline1>Learn more</ButtonOutline1>
                    </AnchorLink>
                </SectionCol>
                <SectionCol xs="12" md="4" css={css`@media(max-width:991px){margin-bottom:50px;}`}>
                    <Row css={css`justify-content:center;`}>
                      <ItemTitle>Recruiting (Coming Soon)</ItemTitle>
                    </Row>
                    <SectionImage src="/page-womenbiz-for-leaders-section1-img4.png"/> 
                    <ItemContent>WomenWorks is a women-first community-based recruitment system for middle management and above, creating accessibility in the hidden job market with curated quality talents.</ItemContent>
                    <AnchorLink id="Womenbiz1-Recruiting-Button" to="/womenbiz-services#womenbiz-section8" title="Our team">
                    <ButtonOutline1>Learn more</ButtonOutline1>
                    </AnchorLink>
                </SectionCol>
                </Row>
          </SectionItem>
      </Container>
  </SectionBackground>
  )
}

